import React from 'react';
import {
    Navbar,
    Nav
} from 'react-bootstrap';

const TopBar = (props) => {

    return (
        <Navbar expand="lg" variant="dark" className="background-primary2 font-sm top-bar d-flex flex-row zindex-1000">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="background-primary2">
                <Nav className="">
                </Nav>
            </Navbar.Collapse>
            <Nav className="ml-auto d-felx flex-row">
               
            </Nav>
        </Navbar>
    );
}

export default TopBar;