import React from 'react'
import cx from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'

import incubation1 from "../assets/img/incubation-cell/incubation1.png"
import incubation2 from "../assets/img/incubation-cell/incubation2.png"
import incubation3 from "../assets/img/incubation-cell/incubation3.png"
import incubation4 from "../assets/img/incubation-cell/incubation4.png"
import incubation5 from "../assets/img/incubation-cell/incubation5.png"
import incubation6 from "../assets/img/incubation-cell/incubation6.png"
import incubation7 from "../assets/img/incubation-cell/incubation7.png"
import incubation8 from "../assets/img/incubation-cell/incubation8.png"


const IncubationCell = (props) => {

    const images = [
        {
            img: incubation1,
            altText: "incubation-image-1"
        },
        {
            img: incubation2,
            altText: "incubation-image-2"
        },
        {
            img: incubation3,
            altText: "incubation-image-3"
        },
        {
            img: incubation4,
            altText: "incubation-image-4"
        },
        {
            img: incubation5,
            altText: "incubation-image-5"
        },
        {
            img: incubation6,
            altText: "incubation-image-6"
        },
        {
            img: incubation7,
            altText: "incubation-image-7"
        },
        {
            img: incubation8,
            altText: "incubation-image-8"
        },
    ]

    const imageSection = images.map((image, index) => <Col key={index} md={3} className='my-2'>
        <img
            src={image.img}
            alt={image.altText}
        />
    </Col>)

    return (
        <Container fluid>
            <Row className={cx("d-none d-md-flex")}>
                <Col md={5} className={cx("d-flex justify-content-center align-items-center headRow1")}>
                    <h2 className={cx("text-white")}>Incubation Cell</h2>
                </Col>
                <Col md={7} className={cx("headRow2")}>
                    
                </Col>
            </Row>
            <Row className={cx("d-md-none")}>
                <Col md={7} className={cx("p-4 d-flex justify-content-start align-items-center headRow2")}>
                    <h2 className={cx("text-white")}>Incubation Cell</h2>
                </Col>
            </Row>

            <Row className={cx("d-flex justify-content-around")}>
                <Col md={9} className={cx("p-4 pt-0 d-flex flex-column justify-content-start")}>
                    <p className='text-large'>
                    Sarhad College has started an incubation center under which various activities
are conducted like the Innovative Startup Contest 2021-22, Online biography
screening on mega Icon Ratan Tata, Guest lectures on innovation and
ecosystem, and Entrepreneurship development activities etc. Various sessions
to promote entrepreneurship are provided by Sarhad Incubation Cell and
students are motivated to become entrepreneurs by providing them guidance
for self employment. Opportunities are provided by the college for startups by
conducting start-up competitions.
                    </p>
                    <p className='text-large'>
                    Students are incubated to learn how to apply theoretical concepts covered in a
college to practical life situations. An entrepreneurial ecosystem is created at
Sarhad College through the incubation cell. The college provides a platform for
start-ups with budding entrepreneurs to convert their innovative ideas into
commercially viable products. Sarhad incubation cell provides facilities where
alumni entrepreneurs interact with students. It is our honor to say that some of
our alumni have already started their own business.
                    </p>
                </Col>

                <Col md={9}>
                    <Row>
                        { imageSection }
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default IncubationCell