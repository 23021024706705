import React from 'react'
import cx from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'

import studentcentric1 from "../assets/img/student-centric-activities/studentcentric1.png"
import studentcentric2 from "../assets/img/student-centric-activities/studentcentric2.png"
import studentcentric3 from "../assets/img/student-centric-activities/studentcentric3.png"
import studentcentric4 from "../assets/img/student-centric-activities/studentcentric4.png"
import studentcentric5 from "../assets/img/student-centric-activities/studentcentric5.png"
import studentcentric6 from "../assets/img/student-centric-activities/studentcentric6.png"
import studentcentric7 from "../assets/img/student-centric-activities/studentcentric7.png"
import studentcentric8 from "../assets/img/student-centric-activities/studentcentric8.png"
import studentcentric9 from "../assets/img/student-centric-activities/studentcentric9.png"
import studentcentric10 from "../assets/img/student-centric-activities/studentcentric10.png"
import studentcentric11 from "../assets/img/student-centric-activities/studentcentric11.png"
import studentcentric12 from "../assets/img/student-centric-activities/studentcentric12.png"
import studentcentric13 from "../assets/img/student-centric-activities/studentcentric13.png"
import studentcentric14 from "../assets/img/student-centric-activities/studentcentric14.png"
import studentcentric15 from "../assets/img/student-centric-activities/studentcentric15.png"
import studentcentric16 from "../assets/img/student-centric-activities/studentcentric16.png"
import studentcentric17 from "../assets/img/student-centric-activities/studentcentric17.png"
import studentcentric18 from "../assets/img/student-centric-activities/studentcentric18.png"
import studentcentric19 from "../assets/img/student-centric-activities/studentcentric19.png"
import studentcentric20 from "../assets/img/student-centric-activities/studentcentric20.png"
import studentcentric21 from "../assets/img/student-centric-activities/studentcentric21.png"
import studentcentric22 from "../assets/img/student-centric-activities/studentcentric22.png"
import studentcentric23 from "../assets/img/student-centric-activities/studentcentric23.png"
import studentcentric24 from "../assets/img/student-centric-activities/studentcentric24.png"
import studentcentric25 from "../assets/img/student-centric-activities/studentcentric25.png"
import studentcentric26 from "../assets/img/student-centric-activities/studentcentric26.png"
import studentcentric27 from "../assets/img/student-centric-activities/studentcentric27.png"
import studentcentric28 from "../assets/img/student-centric-activities/studentcentric28.png"
import studentcentric29 from "../assets/img/student-centric-activities/studentcentric29.png"


const StudentCentricActivities = (props) => {

    const images = [
        {
            img: studentcentric1,
            altText: "student-centric-image-1"
        },
        {
            img: studentcentric2,
            altText: "student-centric-image-2"
        },
        {
            img: studentcentric3,
            altText: "student-centric-image-3"
        },
        {
            img: studentcentric4,
            altText: "student-centric-image-4"
        },
        {
            img: studentcentric5,
            altText: "student-centric-image-5"
        },
        {
            img: studentcentric6,
            altText: "student-centric-image-6"
        },
        {
            img: studentcentric7,
            altText: "student-centric-image-7"
        },
        {
            img: studentcentric8,
            altText: "student-centric-image-8"
        },
        {
            img: studentcentric9,
            altText: "student-centric-image-9"
        },
        {
            img: studentcentric10,
            altText: "student-centric-image-10"
        },
        {
            img: studentcentric11,
            altText: "student-centric-image-11"
        },
        {
            img: studentcentric12,
            altText: "student-centric-image-12"
        },
        {
            img: studentcentric13,
            altText: "student-centric-image-13"
        },
        {
            img: studentcentric14,
            altText: "student-centric-image-14"
        },
        {
            img: studentcentric15,
            altText: "student-centric-image-15"
        },
        {
            img: studentcentric16,
            altText: "student-centric-image-16"
        },
        {
            img: studentcentric17,
            altText: "student-centric-image-17"
        },
        {
            img: studentcentric18,
            altText: "student-centric-image-18"
        },
        {
            img: studentcentric19,
            altText: "student-centric-image-19"
        },
        {
            img: studentcentric20,
            altText: "student-centric-image-20"
        },
        {
            img: studentcentric21,
            altText: "student-centric-image-21"
        },
        {
            img: studentcentric22,
            altText: "student-centric-image-22"
        },
        {
            img: studentcentric23,
            altText: "student-centric-image-23"
        },
        {
            img: studentcentric24,
            altText: "student-centric-image-24"
        },
        {
            img: studentcentric25,
            altText: "student-centric-image-25"
        },
        {
            img: studentcentric26,
            altText: "student-centric-image-26"
        },
        {
            img: studentcentric27,
            altText: "student-centric-image-27"
        },
        {
            img: studentcentric28,
            altText: "student-centric-image-28"
        },
        {
            img: studentcentric29,
            altText: "student-centric-image-29"
        }
    ]

    const imageSection = images.map((image, index) => <Col key={index} md={3} className='my-2'>
        <img
            src={image.img}
            alt={image.altText}
        />
    </Col>)

    return (
        <Container fluid>
            <Row className={cx("d-none d-md-flex")}>
                <Col md={5} className={cx("d-flex justify-content-center align-items-center headRow1")}>
                    <h2 className={cx("text-white")}>Student Centric Activities</h2>
                </Col>
                <Col md={7} className={cx("headRow2")}>
                    
                </Col>
            </Row>
            <Row className={cx("d-md-none")}>
                <Col md={7} className={cx("p-4 d-flex justify-content-start align-items-center headRow2")}>
                    <h2 className={cx("text-white")}>Student Centric Activities</h2>
                </Col>
            </Row>

            <Row className={cx("d-flex justify-content-around")}>
                <Col md={9} className={cx("p-4 pt-0 d-flex flex-column justify-content-start")}>
                    <p className='text-large'>
                        We have always been student-centric and have strived hard for students  holistic development. The scheduling of <b>guest lectures and guidance lectures</b> aids in improving student learning in a more engaging, subject-specific manner.
                    </p>
                    <p className='text-large'>
                        Various competitions are organized that encourage students to achieve more incentives than the winning award. The college arranges <b>industrial visits/ field visits</b> which provide them to explore various industries.
                    </p>
                    <p className='text-large'>
                        Various class level/college level/intercollegiate competitions, activities and competitions by <b>Physics Club, English Literary Club, Finance, HR, Marketing Club, Marathi Vangmay Mandal, and guidance by Competitive Exam Cell </b> for overall development of the students. <b>PowerPoint presentations</b> by students are arranged to foster different skills. <b>Yoga and meditation sessions</b> are conducted to promote the mental and physical health of students.
                    </p>
                    <p className='text-large'>
                        Intercollegiate events like <b>Technofest, Bizzfest, Technoscience, Commerce Dimensions, & Kala-Avishkar</b> are conducted by various departments every year. 
                    </p>
                    <p className='text-large'>
                        Various <b>certificate courses, add on courses, bridge courses, and workshops</b> are provided <b>free of charge</b> to the students to fill the gap between theory and practical knowledge. The  teaching staff is very supportive, highly qualified, and provides individual attention. <b>Remedial coaching, mentoring sessions, and personal counselling</b> is provided to the students.
                    </p>
                    <p className='text-large'>
                        Students are provided <b>problem-solving and question paper-solving sessions</b> which enable them to recognize various approaches and to solve questions in a given time. Aptitude tests are conducted to acknowledge the abilities of students.Through case studies, students can apply their skills and knowledge to a real-life scenario. <b>Project-based learning</b> encourages critical thinking and problem-solving skills in students.
                    </p>
                </Col>

                <Col md={9}>
                    <Row>
                        { imageSection }
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default StudentCentricActivities